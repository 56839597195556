import React from "react";

import { Props } from "..";
import { VideoDataSource } from "../../../../contentstack/content-types/html5-videos/transforms/typings";
import ChampionAbility from "../../../enums/champion-ability";
import { Ability } from "../../../sections/champion/ability-list";
import ComponentImage from "../../../components/image";
import { videoDataSourcesToVideoSources } from "../../../../contentstack/content-types/html5-videos/transforms/video-data-to-video-component";
import { ChampionData } from "../../../datas/data-dragon/champion";
import isExternalUrl from "../../../utils/is-external-url";
import { UrlFieldType } from "../../../../contentstack/fields/url";

export interface SectionGroupChampionOverviewContentstackProps
  extends Pick<Props, "name" | "title" | "blurb" | "lore" | "difficulty" | "roles" | "lanes" | "loreUrl" | "links"> {
  data_dragon_id: string;
  backgroundImageUrl: string;
  skins: Array<{
    name: string;
    imageUrl: string;
  }>;
  abilities: {
    [x in ChampionAbility]: Omit<Ability, "icon" | "videoPoster" | "videoSources"> & {
      iconUrl: string;
      videoData?: {
        posterUrl?: string;
        sources: VideoDataSource[];
      };
    };
  };
  championListLink?: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = ({
  data_dragon_id,
  backgroundImageUrl,
  championListLink,
  name,
  links,
  skins,
  abilities,
  ...otherProps
}: SectionGroupChampionOverviewContentstackProps): Props => {
  return {
    ...otherProps,
    name,
    backgroundImage: <ComponentImage src={backgroundImageUrl} alt="" objectFit="cover" objectPosition="center 20%" />,
    championListLink,

    links: links
      ? links.map(({ url, text }) => {
          return {
            url,
            text,
            target: isExternalUrl(url) ? "_blank" : undefined,
          };
        })
      : undefined,

    skins: skins.map(({ imageUrl, name: skinName }) => {
      return {
        name: skinName === "default" ? name : skinName,
        image: <ComponentImage src={imageUrl} objectFit="cover" alt="" />,
        thumb: <ComponentImage src={imageUrl} objectFit="cover" alt="" />,
      };
    }),
    abilities: {
      [ChampionAbility.PASSIVE]: transformAbility(abilities[ChampionAbility.PASSIVE]),
      [ChampionAbility.Q]: transformAbility(abilities[ChampionAbility.Q]),
      [ChampionAbility.W]: transformAbility(abilities[ChampionAbility.W]),
      [ChampionAbility.E]: transformAbility(abilities[ChampionAbility.E]),
      [ChampionAbility.R]: transformAbility(abilities[ChampionAbility.R]),
    },
  };
};

const transformAbility = ({
  iconUrl,
  videoData,
  ...otherProps
}: SectionGroupChampionOverviewContentstackProps["abilities"][ChampionAbility.E]): Props["abilities"][ChampionAbility.E] => {
  return {
    ...otherProps,
    icon: <ComponentImage src={iconUrl} alt="" />,
    videoPoster:
      videoData && videoData.posterUrl ? (
        <ComponentImage src={videoData.posterUrl} alt="" objectFit="cover" />
      ) : undefined,
    videoSources: videoData && videoData.sources ? videoDataSourcesToVideoSources(videoData.sources) : undefined,
  };
};
