import styled from "styled-components";
import { rem } from "../../utils/style";
import {
  COLOR_BACKGROUND_LIGHT_GREY,
  MEDIA_TABLET_AND_SMALLER,
  MEDIA_MOBILE_ALL,
  MOBILE_MARGIN,
} from "../../layouts/default/theme";
import backgroundPatternReverse from "../article/related-articles/assets/bg-pattern-reverse.png";
import { ImageContainer as ChampionImageContainer } from "../champion-list/components/champion/style";
import { cssLocalizedFontFamilySerif, cssLocalizedUppercase, cssLocalizedItalic } from "../../components/css";

export const Wrapper = styled.section`
  padding: 100px 0;

  background: url(${backgroundPatternReverse}) repeat-x left bottom ${COLOR_BACKGROUND_LIGHT_GREY};
`;

export const Title = styled.h1`
  text-align: center;
  font-size: ${rem(30)};
  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedUppercase}
  font-weight: 800;
  ${cssLocalizedItalic}
  margin-top: 0;
`;

export const ScrollableWrapper = styled.div`
  padding: 35px 0 0 10px;
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
`;

export const Item = styled.span`
  flex: 1 0 auto;
  max-width: 248px;
  padding: 0 20px;
`;

export const ResponsiveWrapper = styled(Wrapper)`
  @media ${MEDIA_TABLET_AND_SMALLER} {
    ${Item} {
      max-width: 160px;
      padding: 0 ${MOBILE_MARGIN * 0.3}px;

      &:first-child {
        padding-left: ${MOBILE_MARGIN}px;
      }
      &:last-child {
        padding-right: ${MOBILE_MARGIN}px;
      }
    }

    ${ChampionImageContainer} {
      min-width: 137px;
      /* max-height: 170px; */
    }
  }
  @media ${MEDIA_MOBILE_ALL} {
    ${Title} {
      font-size: ${rem(16)};
    }
    ${ScrollableWrapper} {
      overflow-y: hidden;
      overflow-x: auto;
      padding: ${MOBILE_MARGIN}px 0;
      margin: 0 ${-MOBILE_MARGIN}px;
    }

    ${List} {
      justify-content: flex-start;
    }
  }
`;
