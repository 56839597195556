import React, { ReactElement } from "react";

import SectionTitle from "../../components/section-title";
import Champion from "../champion-list/components/champion";

import { ResponsiveWrapper, Title, List, Item, ScrollableWrapper } from "./style";
import { Champion as ChampionType } from "../champion-list/@types";
import { useIntl } from "gatsby-plugin-intl";

export interface Props {
  title: string;
  champions: ChampionType[];
}

const Section: React.FC<Props> = ({ title, champions }) => {
  const intl = useIntl();
  title = intl.formatMessage({ id: title });
  return (
    <ResponsiveWrapper>
      <SectionTitle text={title} verticalAlign="center">
        <Title>{title}</Title>
        <ScrollableWrapper>
          <List>
            {champions.map(({ id, ...props }) => {
              return <Item as={Champion} key={id} {...props} />;
            })}
          </List>
        </ScrollableWrapper>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default Section;
