import { TemplateContextData, ContentstackSection } from "../templates/default/typings";
import { ContentstackAllChampionsQuery as Query } from "../../../../generated/graphql";
import { SectionType } from "../../../../lib/templates/champion/typings";
import ChampionAbility from "../../../../lib/enums/champion-ability";
import { ChampionData } from "../../../../lib/datas/data-dragon/champion";
import notEmpty from "../../../utils/not-empty";
import { modularBlockPromo, modularBlockFooterVideo } from "../../landing-pages/transforms/modular-blocks";
import { isChampionRole } from "../../../../lib/enums/champion-role";
import { isChampionLane } from "../../../../lib/enums/champion-lane";
import { ImageAssetUrl } from "../../assets/typings";

const transform = (
  locale: string,
  node: Query["all"]["edges"][0]["node"],
  configurationNode: Query["configuration"]["edges"][0]["node"],
): TemplateContextData => {
  // transform sections
  const sections: ContentstackSection[] = ((node.champion && node.champion.modular_blocks) || [])
    .map((block) => blockToContentstackSection(node, block))
    .filter((section: ContentstackSection | null): section is ContentstackSection => section !== null);

  return {
    uid: node.uid,
    title: `${node.champion_name}, ${node.champion_title}`,
    description: node.champion_blurb,
    ogImageUrl: node.champion_splash,
    sections: [
      createChampionOverviewSection(locale, node, configurationNode),
      ...sections,
      createRelatedChampionsSection(node.related_champions),
      createDownloadGameBasicSection(configurationNode),
      createDownloadAppSection(configurationNode),
    ].filter(notEmpty),
  };
};

export default transform;

type ModularBlock = NonNullable<NonNullable<Query["all"]["edges"][0]["node"]["champion"]>["modular_blocks"]>[0];

const blockToContentstackSection = (
  node: Query["all"]["edges"][0]["node"],
  section: ModularBlock,
): ContentstackSection | null => {
  const keys = Object.keys(section) as Array<keyof ModularBlock>;

  for (const key of keys) {
    if (!section[key]) continue;

    switch (key) {
      case "promo_module":
        return TRANSFORM_MAP[key](section[key]);

      default:
        console.warn(`Section not integrated. (section: ${key})`);
    }
  }

  return null;
};

const TRANSFORM_MAP = {
  promo_module: (section: ModularBlock["promo_module"]): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockPromo(section);
    if (!props) return null;

    return {
      type: SectionType.PROMO,
      props,
    };
  },
};

const createChampionOverviewSection = (
  locale: string,
  node: Query["all"]["edges"][0]["node"],
  configurationNode: Query["configuration"]["edges"][0]["node"],
): ContentstackSection => {
  const dd: ChampionData = JSON.parse(node.data_dragon_json);

  const championOverviewSection: ContentstackSection = {
    type: SectionType.CHAMPION_OVERVIEW,
    props: {
      data_dragon_id: node.data_dragon_id,
      name: node.champion_name,
      title: node.champion_title || undefined,
      blurb: node.champion_blurb,
      lore: node.lore,
      backgroundImageUrl: node.champion_splash,

      roles: node.recommended_roles
        ? node.recommended_roles
            .map((role) => {
              if (isChampionRole(role)) return role;
              return null;
            })
            .filter(notEmpty)
        : undefined,

      lanes: node.recommended_lanes
        ? node.recommended_lanes
            .map((lane) => {
              if (isChampionLane(lane)) return lane;
              return null;
            })
            .filter(notEmpty)
        : undefined,

      links: node.links
        ? node.links
            .map((link) => {
              if (!link) return null;

              return {
                url: link.href,
                text: link.title || link.href,
              };
            })
            .filter(notEmpty)
        : undefined,

      championListLink:
        configurationNode.pages.champion_list && configurationNode.pages.champion_list[0]
          ? {
              url: configurationNode.pages.champion_list[0].url.url,
              internal: true,
            }
          : undefined,
      loreUrl: `https://universe.leagueoflegends.com/${locale}/champion/${node.data_dragon_id.toLowerCase()}/`,
      skins: node.skins,
      difficulty: dd.info.difficulty,
      abilities: {
        [ChampionAbility.PASSIVE]: {
          name: node.champion_passive.champion_passive_name || undefined,
          desc: node.champion_passive.champion_passive_description || undefined,
          iconUrl: node.champion_passive.champion_passive_icon,
          videoData:
            node.champion_passive.champion_passive_video_webm || node.champion_passive.champion_passive_video_mp4
              ? {
                  posterUrl: node.champion_passive.champion_passive_video_poster || undefined,
                  sources: [
                    node.champion_passive.champion_passive_video_webm
                      ? {
                          url: node.champion_passive.champion_passive_video_webm,
                          type: "video/webm",
                        }
                      : null,
                    node.champion_passive.champion_passive_video_mp4
                      ? {
                          url: node.champion_passive.champion_passive_video_mp4,
                          type: "video/mp4",
                        }
                      : null,
                  ].filter(notEmpty),
                }
              : undefined,
        },
        [ChampionAbility.Q]: {
          name: node.champion_q.champion_q_name || undefined,
          desc: node.champion_q.champion_q_description || undefined,
          iconUrl: node.champion_q.champion_q_icon,
          videoData:
            node.champion_q.champion_q_video_webm || node.champion_q.champion_q_video_mp4
              ? {
                  posterUrl: node.champion_q.champion_q_video_poster || undefined,
                  sources: [
                    node.champion_q.champion_q_video_webm
                      ? {
                          url: node.champion_q.champion_q_video_webm,
                          type: "video/webm",
                        }
                      : null,
                    node.champion_q.champion_q_video_mp4
                      ? {
                          url: node.champion_q.champion_q_video_mp4,
                          type: "video/mp4",
                        }
                      : null,
                  ].filter(notEmpty),
                }
              : undefined,
        },
        [ChampionAbility.W]: {
          name: node.champion_w.champion_w_name || undefined,
          desc: node.champion_w.champion_w_description || undefined,
          iconUrl: node.champion_w.champion_w_icon,
          videoData:
            node.champion_w.champion_w_video_webm || node.champion_w.champion_w_video_mp4
              ? {
                  posterUrl: node.champion_w.champion_w_video_poster || undefined,
                  sources: [
                    node.champion_w.champion_w_video_webm
                      ? {
                          url: node.champion_w.champion_w_video_webm,
                          type: "video/webm",
                        }
                      : null,
                    node.champion_w.champion_w_video_mp4
                      ? {
                          url: node.champion_w.champion_w_video_mp4,
                          type: "video/mp4",
                        }
                      : null,
                  ].filter(notEmpty),
                }
              : undefined,
        },
        [ChampionAbility.E]: {
          name: node.champion_e.champion_e_name || undefined,
          desc: node.champion_e.champion_e_description || undefined,
          iconUrl: node.champion_e.champion_e_icon,
          videoData:
            node.champion_e.champion_e_video_webm || node.champion_e.champion_e_video_mp4
              ? {
                  posterUrl: node.champion_e.champion_e_video_poster || undefined,
                  sources: [
                    node.champion_e.champion_e_video_webm
                      ? {
                          url: node.champion_e.champion_e_video_webm,
                          type: "video/webm",
                        }
                      : null,
                    node.champion_e.champion_e_video_mp4
                      ? {
                          url: node.champion_e.champion_e_video_mp4,
                          type: "video/mp4",
                        }
                      : null,
                  ].filter(notEmpty),
                }
              : undefined,
        },
        [ChampionAbility.R]: {
          name: node.champion_r.champion_r_name || undefined,
          desc: node.champion_r.champion_r_description || undefined,
          iconUrl: node.champion_r.champion_r_icon,
          videoData:
            node.champion_r.champion_r_video_webm || node.champion_r.champion_r_video_mp4
              ? {
                  posterUrl: node.champion_r.champion_r_video_poster || undefined,
                  sources: [
                    node.champion_r.champion_r_video_webm
                      ? {
                          url: node.champion_r.champion_r_video_webm,
                          type: "video/webm",
                        }
                      : null,
                    node.champion_r.champion_r_video_mp4
                      ? {
                          url: node.champion_r.champion_r_video_mp4,
                          type: "video/mp4",
                        }
                      : null,
                  ].filter(notEmpty),
                }
              : undefined,
        },
      },
    },
  };

  return championOverviewSection;
};

const createRelatedChampionsSection = (
  relatedChampions: Query["all"]["edges"][0]["node"]["related_champions"],
): ContentstackSection | null => {
  if (!relatedChampions) return null;

  const champions = relatedChampions
    .filter(notEmpty)
    .map(({ uid, champion_name, champion_splash, difficulty, recommended_roles, url, champion }) => {
      return {
        id: uid,
        name: champion_name,
        link: {
          url,
          internal: true,
        },
        difficulty,
        splashImageUrl: champion_splash,
        profileImageUrl:
          champion && champion.profile_image && champion.profile_image.url
            ? (champion.profile_image.url as ImageAssetUrl)
            : undefined,
        roles: recommended_roles
          ? recommended_roles
              .map((role) => {
                if (isChampionRole(role)) return role;
                return null;
              })
              .filter(notEmpty)
          : [],
      };
    });

  if (champions.length <= 0) return null;

  return {
    type: SectionType.RELATED_CHAMPION_LIST,
    props: {
      champions,
    },
  };
};

const createDownloadGameBasicSection = (
  configurationNode: Query["configuration"]["edges"][0]["node"],
): ContentstackSection | null => {
  const props = modularBlockFooterVideo(
    {
      footer_videos_entry: configurationNode.champions.footer_videos,
    },
    {
      url: {
        url: configurationNode.urls.game_download,
        internal: false,
      },
    },
  );
  if (!props) return null;

  return {
    type: SectionType.DOWNLOAD_GAME_BASIC,
    props,
  };
};

const createDownloadAppSection = (
  configurationNode: Query["configuration"]["edges"][0]["node"],
): ContentstackSection | null => {
  if (
    !configurationNode.league_friends_app.app_store_download_url &&
    !configurationNode.league_friends_app.google_play_download_url
  ) {
    return null;
  }
  return {
    type: SectionType.DOWNLOAD_APP,
    props: {
      appleStoreDownloadLink: configurationNode.league_friends_app.app_store_download_url
        ? {
            url: configurationNode.league_friends_app.app_store_download_url,
            internal: false,
          }
        : undefined,
      googleStoreDownloadLink: configurationNode.league_friends_app.google_play_download_url
        ? {
            url: configurationNode.league_friends_app.google_play_download_url,
            internal: false,
          }
        : undefined,
    },
  };
};
