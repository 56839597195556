import styled, { css } from "styled-components";
import {
  COLOR_TEXT_WHITE,
  MAX_WIDTH,
  COLUMN_WIDTH,
  MEDIA_TABLET_AND_SMALLER,
  MEDIA_MOBILE_ALL,
  MOBILE_MARGIN,
  COLOR_OUTLINE_DARK_GREY,
  COLOR_BACKGROUND_DARK_LIGHT,
  COLOR_BACKGROUND_DARK,
} from "../../../layouts/default/theme";
import { rem, easing } from "../../../utils/style";
import {
  WrapperInner as SelectorWrapperInner,
  Option as SelectorOption,
  OptionIcon as SelectorOptionIcon,
  OptionIconContent as SelectorOptionIconContent,
  OptionList as SelectorOptionList,
  OptionBulletContainer as SelectorOptionBulletContainer,
  OptionLine as SelectorOptionLine,
  Baseline as SelectorBaseline,
} from "../../../components/selector/style";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../../components/css";

export const Wrapper = styled.section`
  position: relative;
  padding: 75px 0 ${rem(COLUMN_WIDTH * 4)};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    padding: 0 0 ${rem(MOBILE_MARGIN * 3)};
  }
`;

export const SectionInner = styled.div`
  position: relative;
  z-index: 1;

  box-sizing: border-box;

  display: flex;
  max-width: ${MAX_WIDTH + COLUMN_WIDTH * 4}px;
  padding: 0 ${COLUMN_WIDTH}px 0 0;

  @media ${MEDIA_TABLET_AND_SMALLER} {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 0 ${MOBILE_MARGIN}px;
  }
`;

export const fontStyle = css`
  color: ${COLOR_TEXT_WHITE};
  letter-spacing: 0.05em;
  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedUppercase}
  ${cssLocalizedItalic}
  font-weight: 800;
`;

export const Title = styled.h2`
  position: absolute;
  left: 15px;
  bottom: calc(100%);
  margin-top: 0;
  margin-bottom: 40px;

  ${fontStyle}

  font-size: ${rem(60)};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    display: none;
  }
`;

export const MobileTitle = styled.h2`
  display: none;

  width: 100%;
  margin: 0;
  margin-top: -1px;

  ${fontStyle}
  text-align: center;
  font-size: ${rem(30)};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    display: block;
  }
`;

export const SelectorWrapper = styled.div`
  flex: 2 1 120%;
  padding-top: ${rem(90)};

  ${SelectorBaseline} {
    &.is-left-edge {
      left: auto;
      right: 0;
      width: 200%;
      min-width: 120px;
    }
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    flex: 1 1 auto;
    box-sizing: border-box;
    width: 100%;
    padding: ${MOBILE_MARGIN}px ${rem(COLUMN_WIDTH)};

    /* HACK 2019-10-18 jeremboo: The design of the Selector is different here and never reused somewhere else */
    ${SelectorWrapperInner} {
      padding-bottom: 250px;
      width: calc(100% + 10px);
      margin: 0 -5px;
      overflow: visible;
    }
    ${SelectorOptionList} {
      padding: ${MOBILE_MARGIN * 1.5}px 0;

      &.is-carousel {
        display: none;
      }
    }
    ${SelectorOption} {
      flex: none;
      min-width: auto;
      min-height: auto;
      padding: 0;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    ${SelectorOptionIcon} {
      margin: 0;

      ${SelectorOptionIconContent} {
        width: calc(100% - 10px);
        height: auto;
        transform: none;
      }
    }
    ${SelectorOptionList} {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    ${SelectorOptionBulletContainer}, ${SelectorBaseline}, ${SelectorOptionLine} {
      display: none;
    }
  }

  @media ${MEDIA_MOBILE_ALL} {
    padding: ${MOBILE_MARGIN}px 0;

    ${SelectorWrapperInner} {
      width: calc(100% + 4px);
      margin: 0 -2px;
    }
    ${SelectorOptionIcon} {
      width: 55px;
      height: 55px;

      ${SelectorOptionIconContent} {
        width: calc(100% - 4px);
        top: 2px;
        left: 2px;
      }
    }
  }
`;

export const PreviewWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1 1 100%;
  padding-top: ${rem(60)};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    flex: 1 1 auto;
    padding: ${MOBILE_MARGIN * 2}px ${rem(COLUMN_WIDTH / 3)} ${MOBILE_MARGIN}px;
  }

  @media ${MEDIA_MOBILE_ALL} {
    padding: ${MOBILE_MARGIN * 2}px 0 0 0;
  }
`;

export const AbilityInfo = styled.div`
  position: absolute;
  top: 100%;
  left: ${rem(45)};
  right: 0;

  color: ${COLOR_TEXT_WHITE};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    left: 4px;
    right: 4px;
  }
`;

export const AbilityInfoList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;

  position: relative;
  margin: ${rem(COLUMN_WIDTH / 2)} 0 0;

  @media ${MEDIA_TABLET_AND_SMALLER} {
    margin-top: ${MOBILE_MARGIN}px;
  }
  @media ${MEDIA_MOBILE_ALL} {
    margin: 0;
  }
`;

export const AbilityInfoItem = styled.li`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;

  pointer-events: none;
  opacity: 0;

  transition: opacity 0.4s ${easing.easeInOutCubic};

  &.is-active {
    pointer-events: auto;
    z-index: 1;
    opacity: 1;

    transition-duration: 1s;
    transition-delay: 0.2s;
  }
`;

export const AbilityInfoItemType = styled.h6`
  margin-top: 0;

  font-size: ${rem(10)};
  ${cssLocalizedLineHeight(1)}
  font-weight: normal;
  letter-spacing: 0.15em;
  ${cssLocalizedUppercase}
  color: ${COLOR_OUTLINE_DARK_GREY};
`;

export const AbilityInfoItemName = styled.h5`
  margin-top: ${rem(6)};

  font-size: ${rem(18)};
  ${cssLocalizedLineHeight(20 / 18)}
  font-weight: 700;
  letter-spacing: 0.05em;
  ${cssLocalizedUppercase}
`;

export const AbilityInfoItemDesc = styled.p`
  margin-top: ${rem(6)};

  font-size: ${rem(14)};
  ${cssLocalizedLineHeight(1.4)}
  letter-spacing: 0.05em;

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: ${rem(16)};
  }
`;

export const BackgroundIcon = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${COLOR_BACKGROUND_DARK_LIGHT};

  svg {
    height: calc(100% - 30px);
    fill: ${COLOR_BACKGROUND_DARK};
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    align-items: flex-end;

    svg {
      width: 70%;
      height: auto;
      margin-bottom: 20px;
      max-height: 100%;
    }
  }
`;
