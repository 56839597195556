import React, { useRef, useEffect } from "react";

import { COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE } from "../../../../layouts/default/theme";

import {
  Wrapper,
  WrapperInner,
  VideoContainer,
  VideoWrapper,
  Video,
  NoVideo,
  NoVideoBackgroundImage,
  NoVideoContent,
  NoVideoIcon,
  NoVideoText,
  Hex,
  VIDEO_TRANSITION_DURATION_IN_MS,
} from "./style";

import HexOutline, { HexTransitionType } from "../../../../components/hex-outline";

import noVideoBackgroundImageSrc from "./assets/no-ability-background.jpg";
import noVideoIconSrc from "./assets/no-ability-icon.png";
import { useIntl } from "gatsby-plugin-intl";

interface Props {
  isInViewport: boolean;
  activeAbilityIndex: number;

  videoMap: Array<{
    videoPoster?: React.ReactElement;
    videoSources?: Array<React.ReactElement<React.SourceHTMLAttributes<HTMLSourceElement>>>;
  }>;
  className?: string;
  testId?: string;
}

const StyledHexOutline = Hex.withComponent(HexOutline);

const AbilityPreview: React.FC<Props> = ({ isInViewport = false, activeAbilityIndex, videoMap, className, testId}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);

  // Handle intersection changes
  useEffect(() => {
    if (!videoContainerRef.current) return;

    const videos: HTMLVideoElement[] = Array.from(videoContainerRef.current.querySelectorAll("video"));
    const timeoutIds: number[] = [];

    if (!isInViewport) {
      videos.forEach((video) => video.pause());
    } else {
      videos.forEach((video, index) => {
        const videoIndex = video.getAttribute("data-index");
        if (videoIndex === null) return;
        if (activeAbilityIndex === parseInt(videoIndex, 10)) {
          /* Note: isNaN check added to prevent <=IE11 throwing exception when
                   video metadata not yet loaded when currentTime accessed */
          if (!isNaN(video.duration)) video.currentTime = 0;
          video.play();
        } else {
          video.pause();
          timeoutIds.push(window.setTimeout(() => video.pause(), VIDEO_TRANSITION_DURATION_IN_MS));
        }
      });
    }

    return () => {
      timeoutIds.forEach((timeoutId) => window.clearTimeout(timeoutId));
    };
  }, [isInViewport, activeAbilityIndex]);
  const intl = useIntl();

  return (
    <Wrapper className={className}>
      <WrapperInner>
        <VideoContainer ref={videoContainerRef}>
          {videoMap.map(({ videoPoster, videoSources }, i) => (
            <VideoWrapper className={`${i === activeAbilityIndex ? "is-active" : ""}`} key={i}>
              {videoSources && (
                <Video
                  poster={videoPoster}
                  lazy={!isInViewport || i !== activeAbilityIndex}
                  data-index={i}
                  objectFit={"cover"}
                  objectPosition={"center center"}
                  playsInline
                  loop
                  muted
                  testId={`${testId}-${i}`}
                >
                  {videoSources}
                </Video>
              )}

              {!videoSources && (
                <NoVideo>
                  <NoVideoBackgroundImage
                    src={noVideoBackgroundImageSrc}
                    objectFit={"cover"}
                    objectPosition={"center center"}
                    alt=""
                  />
                  <NoVideoContent>
                    <NoVideoIcon src={noVideoIconSrc} alt="" />
                    {intl.formatMessage({ id: "champion-ability.no-video.title" }) !==
                      "champion-ability.no-video.title" && (
                      <NoVideoText>{intl.formatMessage({ id: "champion-ability.no-video.title" })}</NoVideoText>
                    )}
                  </NoVideoContent>
                </NoVideo>
              )}
            </VideoWrapper>
          ))}
          <StyledHexOutline
            isActive={isInViewport}
            clipRightTop={40}
            offsetAmount={10}
            offsetHorizontal={-1}
            offsetVertical={1}
            strokeColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            accentColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            transition={HexTransitionType.WIRE}
          />
        </VideoContainer>
      </WrapperInner>
    </Wrapper>
  );
};

export default AbilityPreview;
