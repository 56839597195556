import styled from "styled-components";
import { easing } from "../../../../utils/style";
import VideoComponent from "../../../../components/video";
import ComponentImage from "../../../../components/image";
import { cssLocalizedLineHeight, cssLocalizedFontFamilySerif } from "../../../../components/css";

export const VIDEO_TRANSITION_DURATION_IN_MS = 300;

export const Wrapper = styled.div`
  width: 100%;
`;

export const WrapperInner = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const VideoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: opacity ${VIDEO_TRANSITION_DURATION_IN_MS + "ms"} ${VIDEO_TRANSITION_DURATION_IN_MS + "ms"}
    ${easing.easeInOutCubic};

  &.is-active {
    z-index: 1;

    opacity: 1;
    transition-delay: 0ms;
  }
`;

export const Video = styled(VideoComponent)`
  width: 100%;
  height: 100%;
`;

export const NoVideo = styled.div`
  width: 100%;
  height: 100%;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoVideoBackgroundImage = styled(ComponentImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const NoVideoContent = styled.div`
  padding: 20px;
`;

export const NoVideoIcon = styled(ComponentImage)`
  position: relative;
  display: block;
  width: 160px;
  height: 116px;
  margin: 0 auto 15px;

  @media (max-width: 500px) {
    width: 100px;
    height: 72px;
    margin-bottom: 8px;
  }

  @media (max-width: 370px) {
    display: none;
  }
`;

export const NoVideoText = styled.div`
  position: relative;
  max-width: 325px;
  text-align: center;

  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedLineHeight(1)}
  font-size: 20px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.06em;

  @media (max-width: 500px) {
    max-width: 240px;
    font-size: 15px;
  }
`;

export const Hex = styled.div`
  z-index: 1;
`;
