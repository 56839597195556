import styled from "styled-components";
import HexOutline from "../../../components/hex-outline";
import {
  COLOR_BACKGROUND_DARK_LIGHT,
  COLOR_TEXT_WHITE,
  MAX_WIDTH,
  COLUMN_WIDTH,
  MEDIA_MOBILE_ALL,
  MEDIA_DESKTOP_AND_SMALLER,
  COLOR_OUTLINE_TRANSPARENT_WHITE,
} from "../../../layouts/default/theme";
import rem from "../../../utils/style/rem";
import { easing } from "../../../utils/style";
import { fontStyle } from "../ability-list/style";

import {
  Text as SectionTitleText,
  Content as SectionTitleContent,
  LAYOUT_BREAKPOINT as SECTION_TITLE_LAYOUT_BREAKPOINT,
} from "../../../components/section-title/style";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../components/css";
import { rgba } from "polished";

export const BREAKPOINT_COLUMN = 1000;
export const MEDIA_COLUMN_LAYOUT = `(max-width: ${BREAKPOINT_COLUMN}px)`;
export const MEDIA_ROW_LAYOUT = `(min-width: ${BREAKPOINT_COLUMN + 1}px)`;
export const ICON_HEIGHT = 100;
export const ICON_HEIGHT_COLUMN = 60;

export const Wrapper = styled.section`
  @media ${MEDIA_COLUMN_LAYOUT} {
    background: ${COLOR_BACKGROUND_DARK_LIGHT};

    ${SectionTitleText} {
      color: ${COLOR_OUTLINE_TRANSPARENT_WHITE};
    }
  }

  ${SectionTitleContent} {
    box-sizing: border-box;
    padding: 0 ${COLUMN_WIDTH}px;

    @media ${MEDIA_DESKTOP_AND_SMALLER} {
      padding: 0;
    }
  }
`;

export const Content = styled.div`
  max-width: ${rem(MAX_WIDTH - COLUMN_WIDTH * 4)};
  position: relative;
  margin: 5% auto;

  @media ${MEDIA_MOBILE_ALL} {
    margin: 0 -20px;
  }
`;

export const Slideshow = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${(717 / 1215) * 100}%;
`;

export const SlideshowItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  transition: opacity 0.4s 0.2s ${easing.easeInOutCubic};

  &.is-active {
    opacity: 1;
    z-index: 1;
    transition-delay: 0s;
  }
`;

export const SlideshowItemImage = styled.div`
  width: 100%;
  height: 100%;

  > * {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const CarouselHighlight = styled(HexOutline)`
  @media ${MEDIA_COLUMN_LAYOUT} {
    &.row-layout {
      display: none;
    }
  }
  &.col-layout {
    display: none;

    @media ${MEDIA_COLUMN_LAYOUT} {
      display: block;
      z-index: 1;
      height: ${ICON_HEIGHT_COLUMN + "px"};
      bottom: auto;
    }
  }
`;

export const Side = styled.div`
  @media ${MEDIA_ROW_LAYOUT} {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 25vw;
    min-width: 400px;

    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);

    display: flex;
    flex-direction: column;
  }
`;

const Title = styled.h2`
  ${fontStyle}
  color: white;
`;

export const MainTitle = styled(Title)`
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;

  @media (min-width: ${SECTION_TITLE_LAYOUT_BREAKPOINT + 1 + "px"}) {
    display: none;
  }
`;

export const SideTitle = styled(Title)`
  padding: 20px;
  margin: 40px 40px 0 40px;
  font-size: 40px;
  border-bottom: 1px solid ${rgba("#c1c1c1", 0.2)};

  @media ${MEDIA_ROW_LAYOUT} {
    flex: 0 0 auto;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    display: none;
  }
`;

export const CarouselWrapper = styled.div`
  @media ${MEDIA_ROW_LAYOUT} {
    flex: 1 1 auto;
    width: calc(100% + 15px);
    overflow: hidden;
  }
`;

export const CarouselContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media ${MEDIA_ROW_LAYOUT} {
    margin-top: -20vw;
    width: 100%;
    height: calc(100% + 20vw);

    display: flex;
    flex-direction: column;

    > .swiper-container {
      height: 100%;
      > .swiper-wrapper {
        height: fit-content;
      }
    }
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    height: calc(${ICON_HEIGHT + 120}px);
  }
`;

export const CarouselItem = styled.li`
  width: 100%;
  height: 100%;

  @media ${MEDIA_ROW_LAYOUT} {
    box-sizing: border-box;
    padding: 0 0 0 60px;

    &.swiper-slide {
      height: ${ICON_HEIGHT + "px"};
    }
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    margin: 0;
    padding: 25px 5px;

    &.swiper-slide {
      width: ${ICON_HEIGHT_COLUMN + 25 * 2}px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  box-sizing: border-box;
  position: relative;

  padding: 0;
  border: 0;
  background-color: transparent;

  display: flex;
  align-items: center;

  @media ${MEDIA_COLUMN_LAYOUT} {
    flex-direction: column;
  }
`;

export const CarouselItemThumbWrapper = styled.div`
  flex: 0 0 auto;
  position: relative;
  width: ${ICON_HEIGHT}px;
  height: ${ICON_HEIGHT}px;

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: ${ICON_HEIGHT_COLUMN}px;
    height: ${ICON_HEIGHT_COLUMN}px;
    margin: 0 auto;
  }
`;

export const CarouselItemThumb = styled.div`
  flex-shrink: 0;

  overflow: hidden;
  width: 100%;
  height: 100%;

  opacity: 0.7;

  transition: opacity 0.2s ${easing.easeInOutCubic}, transform 0.8s ${easing.easeOutQuart};

  * {
    width: 100%;
    height: 100%;
    display: block;
  }

  @media ${MEDIA_ROW_LAYOUT} {
    transform: scale(0.6);

    ${CarouselItem}:hover & {
      opacity: 1;

      transform: scale(0.65);
    }
  }

  ${CarouselItem}.is-active & {
    opacity: 1;

    transform: scale(1);
  }
`;

export const CarouselItemText = styled.label`
  cursor: pointer;

  display: block;
  text-align: left;
  margin: 20px;

  color: ${COLOR_TEXT_WHITE};
  font-size: 14px;
  ${cssLocalizedLineHeight(18 / 14)}
  font-weight: 600;
  letter-spacing: 0.05em;
  ${cssLocalizedUppercase}

  opacity: 0.7;

  transition: opacity 0.3s ${easing.easeInOutCubic}, transform 0.7s ${easing.easeOutCubic};
  transform: translateX(-24px);

  ${CarouselItem}:hover & {
    opacity: 1;
  }

  ${CarouselItem}.is-active & {
    transform: none;
    opacity: 1;
  }

  @media ${MEDIA_ROW_LAYOUT} {
    flex: 1 1 auto;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    &,
    ${CarouselItem}.is-active & {
      width: 100%;
      text-align: center;
      margin: 10px 0 0;
      transform: none;

      font-size: 10px;
      ${cssLocalizedLineHeight(1.2)}
      letter-spacing: 0.15em;
    }
  }
`;
export const ResponsiveWrapper = styled(Wrapper)``;
