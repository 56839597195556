import React from "react";

import SectionChampionOverview from "../../section-groups/champion-overview";
import SectionRelatedChampionList from "../../sections/related-champion-list";
import SectionDownloadApp from "../../sections/download-app";
import SectionDownloadGameBasic from "../../sections/download-game-basic";
import SectionPromo from "../../sections/promo";

import { SectionType, Section as TemplateSection } from "./typings";

const SECTION_MAP: { [type in SectionType]: any } = {
  [SectionType.CHAMPION_OVERVIEW]: SectionChampionOverview,
  [SectionType.RELATED_CHAMPION_LIST]: SectionRelatedChampionList,
  [SectionType.DOWNLOAD_APP]: SectionDownloadApp,
  [SectionType.DOWNLOAD_GAME_BASIC]: SectionDownloadGameBasic,
  [SectionType.PROMO]: SectionPromo,
};

interface Props {
  sections: TemplateSection[];
}

const Template: React.FC<Props> = ({ sections }) => {
  return (
    <React.Fragment>
      {sections.map(({ type, key, props }, sectionIndex) => {
        const CurrentSection = SECTION_MAP[type];
        if (!CurrentSection) {
          console.warn(`Unsupported section type. (type: ${type})`);
          return;
        }

        return <CurrentSection key={key} {...props} />;
      })}
    </React.Fragment>
  );
};

export default Template;
