import React, { useState } from "react";
import { useInView } from "../../../utils/hooks";
import roleMap from "../../../sections/home/champion-roles/assets/role-map";
import { COLOR_PRIMARY_GOLD, COLOR_OUTLINE_DARK, COLOR_TEXT_WHITE } from "../../../layouts/default/theme";
import ChampionAbility from "../../../enums/champion-ability";
import ChampionRole from "../../../enums/champion-role";

import {
  Wrapper,
  SectionInner,
  Title,
  MobileTitle,
  SelectorWrapper,
  AbilityInfo,
  AbilityInfoList,
  AbilityInfoItem,
  AbilityInfoItemType,
  AbilityInfoItemName,
  AbilityInfoItemDesc,
  PreviewWrapper,
  BackgroundIcon,
} from "./style";

import SectionTitle from "../../../components/section-title";
import SelectorComponent, { SelectorVariant, Option as SelectorComponentOption } from "../../../components/selector";
import AbilityPreview from "./ability-preview";
import { clamp } from "../../../utils/math";
import { useIntl } from "gatsby-plugin-intl";

export interface Ability {
  name?: string;
  desc?: string;
  icon: React.ReactElement;
  videoPoster?: React.ReactElement;
  videoSources?: Array<React.ReactElement<React.SourceHTMLAttributes<HTMLSourceElement>>>;
}

export interface SectionChampionAbilityListProps {
  abilities: {
    [x in ChampionAbility]: Ability;
  };
  roles?: ChampionRole[];
}

const Section: React.FC<SectionChampionAbilityListProps> = ({ abilities, roles }) => {
  const [intersectionRef, inView] = useInView({ threshold: 0 });
  const [activeAbilityIndex, setActiveAbilityIndex] = useState(0);

  const onSelectOption = (index: number, option: SelectorComponentOption) => {
    setActiveAbilityIndex(index);
  };

  const selectorOptions = Object.values(ChampionAbility).map((ability: ChampionAbility) => ({
    id: ability,
    icon: abilities[ability].icon,
  }));

  const RoleIcon = roles && roles.length > 0 && roleMap[roles[0]].icon;
  const intl = useIntl();
  return (
    <Wrapper ref={intersectionRef} data-testid='abilities'>
      <MobileTitle>{intl.formatMessage({ id: "abilities.title" })}</MobileTitle>
      <SectionTitle text={intl.formatMessage({ id: "abilities.title" })} textColor={COLOR_TEXT_WHITE}>
        <SectionInner>
          <SelectorWrapper>
            <SelectorComponent
              options={selectorOptions}
              activeBulletColor={COLOR_PRIMARY_GOLD}
              activeOptionIndex={activeAbilityIndex}
              activeTextColor={COLOR_PRIMARY_GOLD}
              color={COLOR_OUTLINE_DARK}
              onSelectOption={onSelectOption}
              onSwipe={(direction) => {
                setActiveAbilityIndex((current) => clamp(current + direction, 0, selectorOptions.length - 1));
              }}
              variant={SelectorVariant.HEX}
              testId='abilities'
              optionListBefore={<Title data-testid='abilities:title'>{intl.formatMessage({ id: "abilities.title" })}</Title>}
              optionListAfter={
                <AbilityInfo>
                  <AbilityInfoList>
                    {(Object.keys(abilities) as ChampionAbility[]).map((abilityId, index) => {
                      const ability = abilities[abilityId];
                      return (
                        <AbilityInfoItem key={abilityId} className={`${index === activeAbilityIndex && "is-active"}`} data-testid={`abilities:ability-${index}`}>
                          <AbilityInfoItemType data-testid={`abilities:ability:position-${index}`}>
                            {intl.formatMessage({ id: AbilityTitleMap[abilityId] })}
                          </AbilityInfoItemType>
                          {ability.name && <AbilityInfoItemName data-testid={`abilities:name-${index}`}>{ability.name}</AbilityInfoItemName>}
                          {ability.desc && <AbilityInfoItemDesc data-testid={`abilities:description-${index}`}>{ability.desc}</AbilityInfoItemDesc>}
                        </AbilityInfoItem>
                      );
                    })}
                  </AbilityInfoList>
                </AbilityInfo>
              }
            />
          </SelectorWrapper>

          <PreviewWrapper>
            <AbilityPreview
              isInViewport={inView}
              activeAbilityIndex={activeAbilityIndex}
              videoMap={Object.values(abilities).map(({ videoPoster, videoSources }) => ({
                videoPoster,
                videoSources,
              }))}
              testId='abilities'
            />
          </PreviewWrapper>
        </SectionInner>
      </SectionTitle>
      <BackgroundIcon>{RoleIcon && <RoleIcon />}</BackgroundIcon>
    </Wrapper>
  );
};

export default Section;

const AbilityTitleMap: { [x in ChampionAbility]: string } = {
  [ChampionAbility.PASSIVE]: "champion-ability.passive.title",
  [ChampionAbility.Q]: "champion-ability.q.title",
  [ChampionAbility.W]: "champion-ability.w.title",
  [ChampionAbility.E]: "champion-ability.e.title",
  [ChampionAbility.R]: "champion-ability.r.title",
};
