import React from "react";
import SectionChampionOverview, { Props as SectionChampionOverviewProps } from "../../sections/champion/overview";
import SectionChampionAbilityList, { SectionChampionAbilityListProps } from "../../sections/champion/ability-list";
import SectionChampionSkinList, { Props as SectionChampionSkinListProps } from "../../sections/champion/skin-list";
import { useIntl } from "gatsby-plugin-intl";

export interface Props
  extends SectionChampionOverviewProps,
    SectionChampionAbilityListProps,
    Omit<SectionChampionSkinListProps, "title"> {}

const PageSection: React.FC<Props> = (props) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <SectionChampionOverview {...props} />
      <SectionChampionAbilityList {...props} />
      <SectionChampionSkinList title={intl.formatMessage({ id: "available-skins.title" })} {...props} />
    </React.Fragment>
  );
};

export default PageSection;
