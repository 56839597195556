import React from "react";

import Template from "../../../../../lib/templates/champion";

import { TemplateContext, ContentstackSection } from "./typings";
import { SectionType, Section } from "../../../../../lib/templates/champion/typings";
import Meta from "../../../../../lib/components/meta";

import { transformContentstackPropsToSectionProps as transformChampionOverview } from "../../../../../lib/section-groups/champion-overview/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformRelatedChampionList } from "../../../../../lib/sections/related-champion-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformDownloadApp } from "../../../../../lib/sections/download-app/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformDownloadGameBasic } from "../../../../../lib/sections/download-game-basic/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPromo } from "../../../../../lib/sections/promo/transforms/contentstack";
import { useMetaTitle } from "../../../../utils/use-meta-title";
import { graphql, PageProps } from "gatsby";
import transform from "../../transforms/all-champions-query-node-to-default-template-context-data";

interface PageData {
  // TODO:
  all: {
    nodes: any;
  };
  configuration: any;
}
type Props = PageProps<PageData, TemplateContext>;
const byPublishedLocale = (locale: string) => (node: any): boolean => node.publish_details.locale === locale;

const ContentType: React.FC<Props> = ({ pageContext, data: __data }) => {
  const configurationNode = __data.configuration.edges[0].node;
  const language = pageContext.language;
  const byContextLocale = byPublishedLocale(language);
  const node = __data.all.nodes.filter(byContextLocale)[0];

  const data = transform(pageContext.language, node, configurationNode);
  return (
    <React.Fragment>
      <Meta
        title={useMetaTitle(data.title)}
        description={data.description}
        openGraph={{
          image: {
            url: data.ogImageUrl,
          },
        }}
        twitter={{
          image: {
            url: data.ogImageUrl,
          },
        }}
      />
      <Template sections={dataSectionsToSections(data.sections)} />
    </React.Fragment>
  );
};
export default ContentType;

const dataSectionsToSections = (dataSections: ContentstackSection[]): Section[] => {
  return dataSections
    .map((csSection, index) => {
      let props;

      switch (csSection.type) {
        case SectionType.CHAMPION_OVERVIEW:
          props = transformChampionOverview(csSection.props);
          break;

        case SectionType.DOWNLOAD_APP:
          props = transformDownloadApp(csSection.props);
          break;

        case SectionType.DOWNLOAD_GAME_BASIC:
          props = transformDownloadGameBasic(csSection.props);
          break;

        case SectionType.RELATED_CHAMPION_LIST:
          props = transformRelatedChampionList(csSection.props);
          break;

        case SectionType.PROMO:
          props = transformPromo(csSection.props);
          break;
      }

      if (!props) {
        console.warn(`Unable to transform section. (type: ${csSection.type})`);
        return null;
      }

      return {
        type: csSection.type,
        key: `section_${index}`,
        props,
      };
    })
    .filter((section): section is Section => section !== null);
};

export const query = graphql`
  # If the page query cache needs to change with context changes, then we need
  # $path and a query for contextChangeNotInvalidingQueryWorkaround
  # Since this page only uses locale and url, and those are essentially the query
  # cache key anyways, then we don't need to add this hack.
  #
  # query($language: String!, $url: String!, $path: String!) {
  # use our workaround and alias null result to something short (_)
  # that doesn't expose what it does and saves few bytes
  #_: contextChangeNotInvalidingQueryWorkaround(path: $path)

  query($language: String!, $url: String!) {
    all: allContentstackReadOnlyChampions(
      filter: { publish_details: { locale: { eq: $language } }, url: { eq: $url } }
    ) {
      nodes {
        publish_details {
          locale
        }
        uid
        url
        data_dragon_id
        data_dragon_json
        champion_blurb
        lore
        champion_icon
        champion_name
        champion_splash
        champion_title
        recommended_lanes
        recommended_roles
        links {
          href
          title
        }
        champion {
          modular_blocks {
            promo_module {
              promo_module_selection {
                uid
                promo_title
                subtitle
                description
                hide_title
                include_description
                promo_reference {
                  __typename
                  ... on Contentstack_articles {
                    ...ContentstackArticleExcerpt
                  }
                }
                promo_full_width_banner {
                  url
                  uid
                }
              }
            }
          }
        }
        skins {
          name
          imageUrl
        }
        champion_passive {
          champion_passive_description
          champion_passive_icon
          champion_passive_name
          champion_passive_video_mp4
          champion_passive_video_poster
          champion_passive_video_webm
        }
        champion_q {
          champion_q_description
          champion_q_icon
          champion_q_name
          champion_q_video_mp4
          champion_q_video_poster
          champion_q_video_webm
        }
        champion_w {
          champion_w_description
          champion_w_icon
          champion_w_name
          champion_w_video_mp4
          champion_w_video_poster
          champion_w_video_webm
        }
        champion_e {
          champion_e_description
          champion_e_icon
          champion_e_name
          champion_e_video_mp4
          champion_e_video_poster
          champion_e_video_webm
        }
        champion_r {
          champion_r_description
          champion_r_icon
          champion_r_name
          champion_r_video_mp4
          champion_r_video_poster
          champion_r_video_webm
        }
        related_champions {
          uid
          url
          champion_name
          champion_splash
          difficulty
          recommended_roles
          champion {
            profile_image {
              url
            }
          }
        }
      }
    }
    configuration: allContentstackWebsiteConfiguration(filter: { publish_details: { locale: { eq: $language } } }) {
      edges {
        node {
          league_friends_app {
            app_store_download_url
            google_play_download_url
          }
          pages {
            champion_list {
              url {
                url
              }
            }
          }
          champions {
            footer_videos {
              video {
                video {
                  file {
                    url
                    content_type
                  }
                  height
                  width
                }
              }
              title
            }
          }
          urls {
            game_download
          }
        }
      }
    }
  }
`;
