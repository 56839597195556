import { Props as SectionChampionOverviewProps } from "../../section-groups/champion-overview";
import { Props as SectionRelatedChampionListProps } from "../../sections/related-champion-list";
import { Props as SectionDownloadAppProps } from "../../sections/download-app";
import { Props as SectionDownloadGameBasicProps } from "../../sections/download-game-basic";
import { Props as SectionPromoProps } from "../../sections/promo";

export enum SectionType {
  CHAMPION_OVERVIEW = "champion_overview",
  RELATED_CHAMPION_LIST = "related_champion_list",
  DOWNLOAD_APP = "download_app",
  DOWNLOAD_GAME_BASIC = "download_game_basic",
  PROMO = "promo",
}

export type Section =
  | {
      key: string;
      type: SectionType.CHAMPION_OVERVIEW;
      props: SectionChampionOverviewProps;
    }
  | {
      key: string;
      type: SectionType.RELATED_CHAMPION_LIST;
      props: SectionRelatedChampionListProps;
    }
  | {
      key: string;
      type: SectionType.DOWNLOAD_APP;
      props: SectionDownloadAppProps;
    }
  | {
      key: string;
      type: SectionType.DOWNLOAD_GAME_BASIC;
      props: SectionDownloadGameBasicProps;
    }
  | {
      key: string;
      type: SectionType.PROMO;
      props: SectionPromoProps;
    };
