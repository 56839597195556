import { useIntl } from "gatsby-plugin-intl";
import React, { ReactElement, useEffect, useState } from "react";
import CarouselComponent from "../../../components/carousel";
import { HexTransitionType } from "../../../components/hex-outline";
import SectionTitle from "../../../components/section-title";
import { COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE, COLOR_PRIMARY_GOLD } from "../../../layouts/default/theme";
import { useInView, useTicker } from "../../../utils/hooks";
import {
  BREAKPOINT_COLUMN,
  Button,
  CarouselContainer,
  CarouselHighlight,
  CarouselItem,
  CarouselItemText,
  CarouselItemThumb,
  CarouselItemThumbWrapper,
  CarouselWrapper,
  Content,
  MainTitle,
  ResponsiveWrapper,
  Side,
  SideTitle,
  Slideshow,
  SlideshowItem,
  SlideshowItemImage,
} from "./style";

export interface ChampionSkin {
  name: string;
  image: ReactElement;
  thumb: ReactElement;
}

export interface Props {
  title: string;
  skins: ChampionSkin[];
  className?: string;
  intersectionRoot?: HTMLElement | undefined;
}

const CYCLE_DURATION = 4000;

const Section: React.FC<Props> = ({ title, skins, className, intersectionRoot }) => {
  const [activeSkinIndex, setActiveSkinIndex] = useState<number>(0);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [autoCycling, setAutoCycling] = useState(false);
  const [cycleStartTime, setCycleStartTime] = useState(0);

  const [intersectionRef, inView] = useInView({
    root: intersectionRoot,
    threshold: 0,
  });

  useTicker(
    ({ elapsed }) => {
      if (autoCycling) {
        const skinIndex = Math.floor(((elapsed - cycleStartTime) / CYCLE_DURATION) % skins.length);
        if (skinIndex !== activeSkinIndex) setActiveSkinIndex(skinIndex);
      }
    },
    [activeSkinIndex, autoCycling],
  );

  useEffect(() => {
    inView && !hasInteracted ? setAutoCycling(true) : setAutoCycling(false);
    setCycleStartTime(typeof window === "undefined" ? 0 : performance.now());
  }, [inView]);

  const onSelect = (skinIndex: number) => {
    setAutoCycling(false);
    setHasInteracted(true);
    setActiveSkinIndex(skinIndex);
  };
  const intl = useIntl();

  return (
    <ResponsiveWrapper className={className} ref={intersectionRef} data-testid='skins'>
      <SectionTitle
        text={intl.formatMessage({ id: "available-skins.title" })}
        verticalAlign="center"
        displayOnMobile={false}
        className={"section-title"}
      >
        <Content>
          <MainTitle data-testid='skins:title'>{intl.formatMessage({ id: "available-skins.title" })}</MainTitle>
          <Slideshow>
            {skins.map(({ image }, index) => {
              const isActive = index === activeSkinIndex;

              return (
                <SlideshowItem key={`item-${index}`} className={`${isActive ? "is-active" : ""}`} data-testid={`skins:image-${index}`}>
                  <SlideshowItemImage>{image}</SlideshowItemImage>
                </SlideshowItem>
              );
            })}
          </Slideshow>

          <Side>
            <SideTitle>{intl.formatMessage({ id: "available-skins.title" })}</SideTitle>
            <CarouselWrapper>
              <CarouselContainer>
                <CarouselComponent
                  direction="horizontal"
                  freeMode={false}
                  // TODO: what happened to these parameters?
                  centeredSlides={true}
                  activeItemIndex={activeSkinIndex}
                  handleClickImplicitly={false}
                  slideToClickedSlide={false}
                  breakpoints={{
                    [BREAKPOINT_COLUMN + 1]: {
                      direction: "vertical",
                    },
                  }}
                >
                  {skins.map(({ name, thumb }, index) => {
                    const isActive = index === activeSkinIndex;

                    return (
                      <CarouselItem key={`item-${index}`} className={`${isActive ? "is-active" : ""}`}>
                        <Button onClick={() => onSelect(index)} data-testid={`skins:button-${index}`}>
                          {isActive ? (
                            <CarouselHighlight
                              className={"row-layout"}
                              strokeColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
                              accentColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
                              isActive
                              clipRightTop={20}
                              offsetAmount={5}
                              offsetVertical={-1}
                              offsetHorizontal={-1}
                              transition={HexTransitionType.WIRE}
                              transitionDelay={0}
                              transitionDuration={1200}
                            />
                          ) : null}

                          <CarouselItemThumbWrapper>
                            <CarouselItemThumb>{thumb}</CarouselItemThumb>

                            {isActive ? (
                              <CarouselHighlight
                                className={"col-layout"}
                                strokeColor={COLOR_PRIMARY_GOLD}
                                accentColor={COLOR_PRIMARY_GOLD}
                                isActive
                                clipRightTop={15}
                                offsetAmount={4}
                                offsetVertical={1}
                                offsetHorizontal={1}
                                transitionDelay={0}
                                transitionDuration={700}
                              />
                            ) : null}
                          </CarouselItemThumbWrapper>
                          <CarouselItemText>{name}</CarouselItemText>
                        </Button>
                      </CarouselItem>
                    );
                  })}
                </CarouselComponent>
              </CarouselContainer>
            </CarouselWrapper>
          </Side>
        </Content>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default Section;
