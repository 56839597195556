import styled, { keyframes } from "styled-components";
import SectionTitle from "../../../components/section-title";
import HexOutline from "../../../components/hex-outline";
import ChampionDifficulty from "../../../components/indicator/champion-difficulty";
import { rem, easing, animation } from "../../../utils/style";
import { ReactComponent as ChampionIcon } from "./assets/champ-icon.svg";
import { ReactComponent as ScrollIndicatorIcon } from "./assets/scroll-indicator.svg";
import {
  MAX_WIDTH,
  COLUMN_WIDTH,
  COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE,
  COLOR_TEXT_WHITE,
  COLOR_TEXT_DARK_GREY,
  MEDIA_MOBILE_ALL,
  MEDIA_TABLET_AND_SMALLER,
  MEDIA_DESKTOP_AND_SMALLER,
  MOBILE_MARGIN,
  COLOR_PRIMARY_GOLD,
  COLOR_TEXT_LIGHT_GREY,
  COLOR_OUTLINE_DARK,
  COLOR_BACKGROUND_DARK_LIGHT,
} from "../../../layouts/default/theme";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../components/css";

const CHAMPION_SPLASH_WIDTH = 1215;
const CHAMPION_SPLASH_HEIGHT = 717;
const CHAMPION_SPLASH_RATIO = Math.round((CHAMPION_SPLASH_HEIGHT / CHAMPION_SPLASH_WIDTH) * 10000) / 100;

/**
 * * *******************
 * * ANIMATIONS PROPS
 * * *******************
 */
const ANIM_PROPS = {
  specItem: (staggedDelay: number) => ({ delay: 500 + staggedDelay }),
  backgroundImage: { duration: 3000, delay: 500 },
  foregroundAsset: { duration: 2000, delay: 700 },
  infoDivider: { delay: 780, duration: 400 },
  description: { delay: 800 },
  linksLabel: { delay: 900 },
  linksItem: (staggedDelay: number) => ({ delay: 1000 + staggedDelay }),
  scrollIndicator: { delay: 1100, duration: 900 },
};

const bgImageFadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 0.3; }
`;

const lineFadeInKeyframes = keyframes`
  from { transform: scale(1, 0); }
  to { transform: scale(1, 1); }
`;

const animationLinksLine = keyframes`
  from { transform: scaleX( 0 ); }
  to { transform: scaleX( 1 ); }
`;

const scrollIndicatorFadeInKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -5px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

/**
 * * *******************
 * * CSS
 * * *******************
 */

export const StyledSectionTitle = styled(SectionTitle)`
  position: absolute;
  top: 31%;
`;

export const BackgroundAsset = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 80vh;
  max-height: 800px;
  overflow: hidden;
`;

export const BackgroundImage = styled.div`
  position: absolute;
  left: -5%;
  top: -5%;

  width: 110%;
  height: 110%;

  > * {
    display: block;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    ${animation.animationMixin(bgImageFadeIn, ANIM_PROPS.backgroundImage)}
  }

  &::after {
    content: "";

    position: absolute;
    left: 0;
    bottom: -10%;

    width: 100%;
    height: 50%;

    background: linear-gradient(to bottom, transparent, ${COLOR_BACKGROUND_DARK_LIGHT} 70%);
  }
`;

export const SectionInner = styled.div`
  box-sizing: border-box;

  max-width: ${CHAMPION_SPLASH_WIDTH + COLUMN_WIDTH * 2 + "px"};
  padding: 0 ${rem(COLUMN_WIDTH)};
  margin: 0 auto;

  @media ${MEDIA_DESKTOP_AND_SMALLER} {
    padding: 0 ${rem(MOBILE_MARGIN / 2)};
  }
`;

export const ForegroundAsset = styled.div`
  overflow: hidden;

  position: relative;

  width: 100%;
  padding-bottom: ${CHAMPION_SPLASH_RATIO}%;

  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0) 98%);

  * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    ${animation.fadeIn(ANIM_PROPS.foregroundAsset)}
  }
`;

export const Dock = styled.div`
  position: relative;

  margin: ${rem(-COLUMN_WIDTH * 1.5)} ${rem(COLUMN_WIDTH)} 0;

  @media ${MEDIA_MOBILE_ALL} {
    margin: ${rem(-COLUMN_WIDTH * 0.5)} 0 0;
  }
`;

export const Name = styled.div`
  position: relative;
  top: ${rem(-80)};
  margin-bottom: ${rem(-80)};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    top: ${rem(-60)};
    margin-bottom: ${rem(-65)};
  }

  @media ${MEDIA_MOBILE_ALL} {
    top: ${rem(-43)};
    margin-bottom: ${rem(-43)};

    & > h1 > strong > div > span {
      font-size: ${rem(40)};
      letter-spacing: 0.1em;
    }

    & > h1 > span {
      font-size: ${rem(14)};
      ${cssLocalizedLineHeight(65 / 14)}
      letter-spacing: 0.16em;
      position: relative;
      top: 34px;
    }
  }
`;

export const Info = styled.div`
  position: relative; /* ensure it appears above the hex outline */
  display: flex;

  @media ${MEDIA_TABLET_AND_SMALLER} {
    flex-wrap: wrap;
  }
`;

export const Specs = styled.div`
  box-sizing: border-box;
  width: 50%;
  padding: ${rem(COLUMN_WIDTH)};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    width: 100%;
    padding: ${rem(MOBILE_MARGIN)} ${rem(COLUMN_WIDTH)};
  }

  @media ${MEDIA_MOBILE_ALL} {
    padding: ${rem(MOBILE_MARGIN)} ${rem(MOBILE_MARGIN / 2)};
  }
`;

export const Desc = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 50%;
  padding: ${rem(COLUMN_WIDTH)};

  ${animation.fadeIn(ANIM_PROPS.description)}

  p {
    max-width: 100%; /* IE10 fix */
    margin-top: 0;

    color: ${COLOR_TEXT_WHITE};
    font-size: ${rem(14)};
    ${cssLocalizedLineHeight(18 / 14)}
    letter-spacing: 0.08em;

    button {
      padding: 0;
      border: 0;
      background-color: transparent;
      color: ${COLOR_PRIMARY_GOLD};
      font-size: ${rem(10)};
      ${cssLocalizedUppercase}
    }
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    width: 100%;
    padding: ${rem(MOBILE_MARGIN)} ${rem(COLUMN_WIDTH)};
    padding-bottom: ${rem(MOBILE_MARGIN * 1.5)};
  }
  @media ${MEDIA_MOBILE_ALL} {
    padding: ${rem(MOBILE_MARGIN)};
    padding-bottom: ${rem(MOBILE_MARGIN * 2)};
    font-size: 10px;
    letter-spacing: 0.04em;
  }
`;

export const InfoDivider = styled.div`
  width: 1px;
  margin: ${rem(40)} 0;

  background: ${COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE};

  transform-origin: top center;
  ${animation.animationMixin(lineFadeInKeyframes, ANIM_PROPS.infoDivider)}

  @media ${MEDIA_TABLET_AND_SMALLER} {
    width: calc(100% - ${rem(300)});
    height: 1px;
    margin: 0 auto;
  }
  @media ${MEDIA_MOBILE_ALL} {
    width: calc(100% - ${rem(100)});
  }
`;

export const SpecsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: space-around;
  -ms-flex-pack: distribute; /* IE10 fix */
  width: 100%;
`;

export const SpecsItem = styled.li<{ animationDelay: number }>`
  display: block;
  text-align: center;

  color: ${COLOR_PRIMARY_GOLD};
  font-size: ${rem(10)};
  letter-spacing: 0.15em;
  ${cssLocalizedUppercase}

  ${({ animationDelay }) => animation.fadeIn(ANIM_PROPS.specItem(animationDelay))}
`;

export const SpecsItemIcon = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
`;

export const SpecsItemType = styled.div`
  width: 100%;
  margin-top: ${rem(15)};

  color: ${COLOR_TEXT_WHITE};
`;

export const SpecsItemValue = styled.div`
  width: 100%;
  margin-top: ${rem(2)};

  color: ${COLOR_PRIMARY_GOLD};
  font-weight: normal;
`;

export const StyledChampionDifficulty = styled(ChampionDifficulty)`
  display: block;
  width: 50px;
  position: absolute;
  top: 20px;
`;

export const StyledSpecsIcon = styled.svg`
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  padding: 7px;
  fill: ${COLOR_PRIMARY_GOLD};
`;

export const ChampionsButtonContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 30px;
  color: ${COLOR_TEXT_WHITE};
  ${cssLocalizedUppercase}
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.16em;
  ${cssLocalizedLineHeight(1)}
  text-align: left;
`;

export const ChampionsButton = styled.a`
  position: relative;
  display: block;
  height: 100%;
  color: ${COLOR_TEXT_WHITE};
  padding: 10px 20px;

  &:hover {
    text-decoration: none;
  }
`;

export const ChampionsButtonText = styled.span`
  vertical-align: middle;
  padding-right: 10px;
`;

export const ChampionsButtonIcon = styled(ChampionIcon)`
  fill: ${COLOR_TEXT_WHITE};
  height: 14px;
  width: 14px;
  vertical-align: middle;
`;

export const StyledHexOutline = styled(HexOutline)``;

export const LinksWrapper = styled.div`
  position: relative;

  width: 100%;

  &::before {
    content: "";

    position: absolute;
    left: 1px;
    top: -1px;

    width: calc(100% - 2px);
    height: 1px;

    background: ${COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE};

    transform-origin: left center;
    animation: ${animationLinksLine} backwards 1.5s 1.32s ${easing.easeOutExpo};
  }

  @media ${MEDIA_MOBILE_ALL} {
    &::before {
      top: 1px;
    }
  }
`;

export const Links = styled.div`
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${rem(14)} ${rem(COLUMN_WIDTH)};

  white-space: nowrap;

  @media ${MEDIA_MOBILE_ALL} {
    overflow-x: auto;
    overflow-y: hidden;

    justify-content: flex-start;
    padding: ${rem(20)} 0;
  }
`;

export const LinksLabel = styled.p`
  margin: 0 ${rem(20)};

  color: ${COLOR_TEXT_DARK_GREY};
  font-size: ${rem(10)};
  letter-spacing: 0.2em;
  ${cssLocalizedUppercase}

  ${animation.fadeIn(ANIM_PROPS.linksLabel)}
`;

export const LinksList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
`;

export const LinksItem = styled.li<{ animationDelay: number }>`
  margin: 0 ${rem(20)};

  font-size: ${rem(10)};
  letter-spacing: 0.15em;
  ${cssLocalizedUppercase}

  ${({ animationDelay }) => animation.fadeIn(ANIM_PROPS.linksItem(animationDelay))}
`;

export const LinksItemLink = styled.a`
  color: ${COLOR_TEXT_WHITE};
`;

export const ScrollIndicator = styled(ScrollIndicatorIcon)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  stroke: ${COLOR_OUTLINE_DARK};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    display: none;
  }

  ${animation.animationMixin(scrollIndicatorFadeInKeyframes, ANIM_PROPS.scrollIndicator)}
`;

export const Wrapper = styled.section`
  position: relative;

  min-height: 75vh;
  padding: 30px 0 75px 0;

  background: ${COLOR_BACKGROUND_DARK_LIGHT};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    padding-bottom: ${rem(100)};
  }

  @media ${MEDIA_MOBILE_ALL} {
    padding-top: ${rem(10)};
    padding-bottom: ${rem(20)};

    ${ChampionsButtonContainer} {
      width: 36px;
      height: 36px;
      top: 26px;
      right: 20px;
    }

    ${ChampionsButton} {
      padding: 0;
    }

    ${ChampionsButtonText} {
      ${cssLocalizedLineHeight(0)}
      font-size: 0;
      color: transparent;
    }

    ${ChampionsButtonIcon} {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 9px;
      top: 9px;
    }
  }
`;
