import React from "react";
import { Props } from "..";
import ComponentImage from "../../../components/image";
import { ImageAssetUrl } from "../../../../contentstack/content-types/assets/typings";
import { UrlFieldType } from "../../../../contentstack/fields/url";

export interface SectionRelatedChampionListContentstackProps {
  champions: Array<
    Pick<Props["champions"][0], "id" | "difficulty" | "name" | "roles"> & {
      link: UrlFieldType;
      splashImageUrl: string;
      profileImageUrl?: ImageAssetUrl;
    }
  >;
}

export const transformContentstackPropsToSectionProps = (props: SectionRelatedChampionListContentstackProps): Props => {
  return {
    title: "related-champions.title",
    champions: props.champions.map(({ splashImageUrl, profileImageUrl, ...championProps }) => {
      const imageUrl = profileImageUrl ? profileImageUrl : splashImageUrl;
      return {
        ...championProps,
        image: <ComponentImage alt="" src={imageUrl} objectFit="cover" />,
      };
    }),
  };
};
