import React, { useRef, useState, useCallback, useEffect } from "react";
import { ease } from "../../../utils/math";

import {
  COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE,
  COLOR_TEXT_WHITE,
  COLOR_PRIMARY_BLUE,
} from "../../../layouts/default/theme";

import { laneSvgMap, laneNameMap } from "../../../assets/lanes";
import { roleSvgMap, roleNameMap } from "../../../assets/roles";
import ChampionRole from "../../../enums/champion-role";
import ChampionLane from "../../../enums/champion-lane";
import { levelToRanking, CHAMPION_DIFFICULTIES } from "../../../components/indicator/champion-difficulty";

import {
  Wrapper,
  StyledSectionTitle,
  StyledHexOutline,
  ChampionsButtonContainer,
  ChampionsButton,
  ChampionsButtonText,
  ChampionsButtonIcon,
  BackgroundAsset,
  BackgroundImage,
  SectionInner,
  ForegroundAsset,
  Dock,
  Name,
  Info,
  Specs,
  SpecsList,
  SpecsItem,
  SpecsItemIcon,
  SpecsItemType,
  SpecsItemValue,
  StyledChampionDifficulty,
  StyledSpecsIcon,
  InfoDivider,
  Desc,
  LinksWrapper,
  Links,
  LinksLabel,
  LinksList,
  LinksItem,
  LinksItemLink,
  ScrollIndicator,
} from "./style";

import GiantTitle from "../../../components/giant-title";
import HexOutline, { HexTransitionType, Controller as HexOutlineController } from "../../../components/hex-outline";
import { useResize } from "../../../utils/hooks";
import LinkComponent, { LinkProps } from "../../../components/link";
import { useIntl } from "gatsby-plugin-intl";

export interface Props {
  name: string;
  title?: string;
  blurb: string;
  lore: string;
  difficulty: number;
  roles?: ChampionRole[];
  lanes?: ChampionLane[];
  backgroundImage: React.ReactElement;
  loreUrl: string;
  links?: Array<{
    url: string;
    text: string;
    target?: string;
  }>;
  championListLink?: LinkProps & {
    text?: string;
  };
}

const Section: React.FC<Props> = ({
  name,
  title,
  blurb,
  lore,
  difficulty: difficultyValue,
  roles,
  lanes,
  backgroundImage,
  loreUrl,
  links,
  championListLink,
}) => {
  const [championsButtonHover, setChampionsButtonHover] = useState<boolean>(false);
  const titleRef = useRef<HTMLElement>(null);

  const role = roles && roles.length > 0 ? roles[0] : null;
  const RoleIcon = role ? StyledSpecsIcon.withComponent(roleSvgMap[role]) : null;

  const lane = lanes && lanes.length > 0 ? lanes[0] : null;
  const LaneIcon = lane ? StyledSpecsIcon.withComponent(laneSvgMap[lane]) : null;

  const difficulty = levelToRanking(difficultyValue);

  const [titleNameWidth, setTitleNameWidth] = useState(0);
  const [showLore, setShowLore] = useState<boolean>(false);

  const [hexOutlineController, setHexOutlineController] = useState<HexOutlineController>();
  const onInitHexOutline = useCallback((controller) => setHexOutlineController(controller), []);

  // refresh hex outline when showLore changes, since the height of the component may have changed
  useEffect(() => {
    if (!hexOutlineController) return;
    hexOutlineController.refresh();
  }, [showLore, hexOutlineController]);

  useResize(() => {
    if (titleRef.current) setTitleNameWidth(titleRef.current.offsetWidth);
  }, [titleRef]);
  const intl = useIntl();
  return (
    <Wrapper data-testid='overview'>
      <BackgroundAsset>
        <BackgroundImage>{backgroundImage}</BackgroundImage>
      </BackgroundAsset>
      <StyledSectionTitle
        text={intl.formatMessage({ id: "overview.title" })}
        textColor={COLOR_TEXT_WHITE}
        displayOnMobile={false}
      ></StyledSectionTitle>
      <SectionInner>
        <ForegroundAsset data-testid='overview:backgroundimage'>{backgroundImage}</ForegroundAsset>
        <Dock>
          <HexOutline
            isActive
            strokeColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            accentColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            transition={HexTransitionType.WIRE}
            transitionDuration={1500}
            transitionDelay={500}
            maskTop={titleNameWidth}
            onInit={onInitHexOutline}
          />
          <Name>
            <GiantTitle
              titleRef={titleRef}
              text={`${title ? `${title}\n` : ""}${name}`}
              toggleContrast
              transitionDelay={500}
              testId='overview'
            />
          </Name>
          <Info>
            <Specs>
              <SpecsList>
                {role && RoleIcon && (
                  <SpecsItem animationDelay={0}>
                    <SpecsItemIcon>
                      <RoleIcon data-testid='overview:roleicon'/>
                    </SpecsItemIcon>
                    <SpecsItemType data-testid='overview:rolestring'>{intl.formatMessage({ id: "champion-role.title" })}</SpecsItemType>
                    <SpecsItemValue data-testid='overview:role'>{intl.formatMessage({ id: roleNameMap[role] })}</SpecsItemValue>
                  </SpecsItem>
                )}
                {lane && LaneIcon && (
                  <SpecsItem animationDelay={100}>
                    <SpecsItemIcon>
                      <LaneIcon />
                    </SpecsItemIcon>
                    <SpecsItemType>{intl.formatMessage({ id: "most-played.title" })}</SpecsItemType>
                    <SpecsItemValue>{intl.formatMessage({ id: laneNameMap[lane] })}</SpecsItemValue>
                  </SpecsItem>
                )}
                <SpecsItem animationDelay={200}>
                  <SpecsItemIcon>
                    <StyledChampionDifficulty ranking={difficulty} testId='overview:difficultyicon'/>
                  </SpecsItemIcon>
                  <SpecsItemType data-testid='overview:difficultystring'>{intl.formatMessage({ id: "champion-difficulty.title" })}</SpecsItemType>
                  <SpecsItemValue data-testid='overview:difficulty'>{intl.formatMessage({ id: CHAMPION_DIFFICULTIES[difficulty] })}</SpecsItemValue>
                </SpecsItem>
              </SpecsList>
            </Specs>
            <InfoDivider />
            <Desc>
              <p data-testid='overview:description'>
                {showLore ? lore : blurb}{" "}
                {!showLore && (
                  <button onClick={() => setShowLore(true)} data-testid='overview:seemorebutton'>{intl.formatMessage({ id: "see-more.action" })}</button>
                )}
              </p>
            </Desc>
          </Info>
          {links && links.length > 0 && (
            <LinksWrapper>
              <Links>
                <LinksLabel>{intl.formatMessage({ id: "champion-mastery.title" })}</LinksLabel>
                <LinksList>
                  {links.map(({ url, target, text }, idx) => {
                    return (
                      <LinksItem key={url + text} animationDelay={idx * 100}>
                        <LinksItemLink href={url} target={target} data-testid={`overview:link-${idx}`}>
                          {text}
                        </LinksItemLink>
                      </LinksItem>
                    );
                  })}
                </LinksList>
              </Links>
            </LinksWrapper>
          )}
        </Dock>

        {championListLink && (
          <ChampionsButtonContainer
            onMouseEnter={() => setChampionsButtonHover(true)}
            onMouseLeave={() => setChampionsButtonHover(false)}
          >
            <StyledHexOutline
              isActive
              clipLeftTop={9}
              clipRightBot={9}
              strokeColor={COLOR_PRIMARY_BLUE}
              fillColor={"rgba(0, 9, 19, 0.6)"}
              interactive
              hovering={championsButtonHover}
              hoverDuration={300}
              hoverEase={ease.outExpo}
            />
            <ChampionsButton {...championListLink} as={LinkComponent} testId='overview:championlistbutton'>
              <ChampionsButtonText>
                {championListLink.text || intl.formatMessage({ id: "champion-list.title" })}
              </ChampionsButtonText>
              <ChampionsButtonIcon />
            </ChampionsButton>
          </ChampionsButtonContainer>
        )}
      </SectionInner>
      <ScrollIndicator />
    </Wrapper>
  );
};

export default Section;
